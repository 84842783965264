<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @cancel="handleModalCancel"
        width="60%"
        :destroy-on-close="true"
        :footer="null">
        <AForm
            class="myform"
            :wrapper-col="{ span: 14 }">
            <AFormItem
                v-for="(item, index) in state.data"
                :key="index"
                :label="item.label"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }">
                <AInput
                    disabled
                    :value="item.answer"
                    style="width:100%;"/>
            </AFormItem>
        </AForm>
    </a-modal>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
} from 'vue'

export default defineComponent({
    components: {
        // 
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            title: 'Lihat Hasil Form Survey',
            data: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        /**
         * handle func vue
         **/
        onMounted(() => {
            state.data = props.item.result
        })

        return {
            state,
            handleModalCancel,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
